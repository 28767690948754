.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.container {
  position: relative;

  display: flex;
  flex-direction: column;
}

.mobileContainer {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  padding-top: 16px;
}

.wrapper {
  display: flex;
  gap: 8px;

  align-items: center;
}

.linkInfo {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-bottom: 16px;

  border-bottom: 1px solid var(--dark-12);
}

.descText {
  font-size: 15px;
  line-height: 20px;

  margin-top: 8px;

  color: var(--dark-48);
}

.iconImg {
  width: 20px;
  height: 20px;

  pointer-events: none;
}

.noDataText {
  color: var(--dark-48);
}

.noDataAction {
  cursor: pointer;

  color: var(--dark-48);
}

.noDataAction:hover {
  color: var(--dark-64);
}

.noDataAction:active {
  color: var(--white);
}

.addLinkContainer {
  display: flex;
  align-items: center;

  margin-bottom: 20px;

  cursor: pointer;
  gap: 8px;
}

.addLinkIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border-radius: 4px;
  background-color: var(--white);
}

.addLinkIcon {
  width: 12px;
  height: 12px;
  stroke: var(--black);
}

.addLinkContainer:hover > * {
  color: var(--dark-64);
}

.deleteIcon {
  width: 16px;
  height: 16px;
  margin-left: 4px;

  transition: stroke 150ms ease;
  stroke: var(--dark-32);
}

.deleteIcon:hover {
  stroke: var(--red);
}

@media (max-width: 540px) {
  .noDataText {
    text-align: center;
  }

  .list {
    display: flex;
    flex-wrap: wrap;

    padding: 0 20px;
  }
}
