.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
  margin-top: 24px;
}

.text {
  max-width: 75%;

  color: var(--dark-48);
}

.buttonContainer {
  margin-top: 32px;
}

@media (max-width: 540px) {
  .container {
    align-items: center;

    margin: 0 !important;
    padding: 0 8px;

    text-align: center;
  }
}
