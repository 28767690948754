.mobilePage {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-width: 375px;
  max-width: 100vw;
  min-height: 100vh;

  background-color: var(--dark-08);
}

.loading {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;

  /* min-height: 100vh; */
}

.profileCardListWrapper {
  display: flex;
  flex-direction: column;
}

.error {
  margin: 0;
  padding-top: 12px;
  padding-left: 40px;
}

.headerBackground {
  position: absolute;

  width: 100%;
  height: 72px;

  background: linear-gradient(0.25turn, #3d81a6, #3b2c3a);
}

.headerThemeImageWrapper {
  width: 100%;
  height: 100%;

  background: linear-gradient(0.25turn, #3d81a6, #3b2c3a);
  background-position: 0% 60%;
  background-size: cover;
}

.headerThemeImage {
  height: 74px;

  background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 0.0086472) 6.67%, rgba(22, 22, 22, 0.03551) 13.33%, rgba(22, 22, 22, 0.0816599) 20%, rgba(22, 22, 22, 0.147411) 26.67%, rgba(22, 22, 22, 0.231775) 33.33%, rgba(22, 22, 22, 0.331884) 40%, rgba(22, 22, 22, 0.442691) 46.67%, rgba(22, 22, 22, 0.557309) 53.33%, rgba(22, 22, 22, 0.668116) 60%, rgba(22, 22, 22, 0.768225) 66.67%, rgba(22, 22, 22, 0.852589) 73.33%, rgba(22, 22, 22, 0.91834) 80%, rgba(22, 22, 22, 0.96449) 86.67%, rgba(22, 22, 22, 0.991353) 93.33%, #161616 100%);
}

.profileInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 0 20px;
}

.logoContainer {
  z-index: 10;

  align-self: flex-start;

  width: 39px;
  height: 24px;
  margin-top: 16px;
  margin-bottom: 22px;
}

.logoContainer > * {
  fill: var(--white) !important;
}

.userAvatar {
  width: 96px;
  height: 96px;
}

.userName {
  align-self: center;

  margin-top: 10px;
}

.userDescription {
  margin-top: 20px;

  text-align: center;

  color: var(--dark-64);
}

.socialLinks {
  margin-top: 17px;
}

.socialIcon {
  width: 43px;
  height: 43px;

  background-color: transparent !important;
}

.linksContent {
  display: flex;
  gap: 26px;
}

.social {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-bottom: 24px;
}

.gamesInfo {
  padding: 20px 20px 24px;

  border-top: 1px solid var(--dark-12);
}

.gameIcons {
  gap: 16px;
}

.gameIconsLabel {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;

  text-transform: none !important;

  color: var(--white);
}

.linksLabel {
  font-weight: 600 !important;

  margin-bottom: 2px;
}

.sectionTitle {
  font-size: 12px;
  line-height: 14px;

  margin-top: 24px;
  margin-bottom: 12px;
  padding: 0 16px;

  color: var(--dark-64);
}

.myGamesTitleWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  padding-right: 16px;
}

.addGamesButton {
  width: fit-content;
  height: fit-content;
  margin-bottom: 12px;
  padding: 4px 16px !important;
}

.addGamesButton > * {
  font-size: 12px !important;
  line-height: 14px !important;
}

.userShopBannerContainer {
  position: relative;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 8px 0;
  padding: 24px;

  background-color: var(--green);
}

.userShopBanner {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;

  text-align: center;
  gap: 4px;
}

.userShopBannerLabel {
  margin-bottom: 4px;

  color: var(--dark);
}
